<template>
  <div>
    <GradeBook />
  </div>
</template>

<script>
import GradeBook from "../components/ReportCenter/GradeBook";
export default {
  components: {
    GradeBook,
  },
};
</script>

<style></style>
